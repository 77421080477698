import React, { useEffect } from 'react';
import logo from '../assets/logo.png';
import { Mixpanel } from '../Mixpanel';

const Privacy = () => {

    useEffect(() => {
        Mixpanel.track("Visit /privacy");
    }, [])

    return (<div className="App">
        <header className="App-header">

            <div style={{ display: 'flex' }}>
                <img className='logo' src={logo}></img>
            </div>

            <h1 className='headline'>Privacy Policy</h1>

            <div className='content-text'>
            We respect your privacy. This extension runs privately within your web browser, and does not track or record any user information. The settings data is stored locally within your browser's storage.
            </div>

        </header>
    </div>)
}

export default Privacy;