import React, { useEffect } from 'react';
import logo from '../assets/logo.png';
import { Mixpanel } from '../Mixpanel';

const HowToUpload = () => {

    useEffect(() => {
        Mixpanel.track("Visit /how-to-upload");
    }, [])

    return (<div className="App">

            <div className="arrow" style={{
                margin: 0,
                position: 'absolute',
                top: '0px',
                right: '250px',
                fontSize: '10rem',
            }}>↗</div>
            <video autoPlay muted loop className="demo-video" style={{
                position: 'absolute',
                top: '200px',
                right: '400px',
                width: '600px'
            }}>
                <source src="https://audiblogs-conversions.s3.us-west-2.amazonaws.com/images/tooltips-temp/how-to-upload.mp4" type="video/mp4" />
            </video>

    </div>)
}

export default HowToUpload;