import React, { useEffect } from 'react';
import logo from '../assets/logo.png';
import mixpanel from 'mixpanel-browser';
mixpanel.init(process.env.REACT_APP_MIXPANEL_API_KEY, {
  api_host: process.env.REACT_APP_MIXPANEL_API_HOST,
  ignore_dnt: true,
});

// Import useeffect
const Installed = () => {
    function highlight() {
        let i = 0;
        const interval = setInterval(() => {
          const char = document.getElementById(`char-${i}`);
          char.style.backgroundColor = "#ACCEF7";
          i++;
          if (i > 18) {
            clearInterval(interval);
            setTimeout(() => {
              while (i > 0) {
                i--;
                const char = document.getElementById(`char-${i}`);
                char.style.backgroundColor = "";
                if (i === 0) {
                  highlight();
                }
              }
            }, 2000);
          }
        }, 30);
      }
      
      
      
    useEffect(() => {
        highlight();
        // Track the install event
        mixpanel.track('Visit /installed');

        mixpanel.identify()
        mixpanel.people.set()
    
        // Set Once
        mixpanel.people.set_once({
          utm_source: mixpanel.get_property('utm_source'),
          utm_medium: mixpanel.get_property('utm_medium'),
          utm_campaign: mixpanel.get_property('utm_campaign'),
          utm_term: mixpanel.get_property('utm_term'),
          search_engine: mixpanel.get_property('$search_engine'),
          search_keyword: mixpanel.get_property('mp_keyword'),
          referrer: mixpanel.get_property('$referrer'),
          initial_referrer: mixpanel.get_property('$initial_referrer'),
  
        });

    
    }, []);


    return <div className="App">
    <header className="App-header">
        <div style={{ display: 'flex' }}>
            <img className='logo' src={logo}></img>
            {/* <div className='header-brand'>Bullets</div> */}
        </div>

        <h1 className='headline'>👋 Welcome!</h1>
        <h2 className='subheadline' style={{marginBottom: "20px"}}>
        Open a new page or refresh an existing tab <br></br>
            {'and '}
            <span id="char-0" >h</span>
            <span id="char-1" >i</span>
            <span id="char-2" >g</span>
            <span id="char-3" >h</span>
            <span id="char-4" >l</span>
            <span id="char-5" >i</span>
            <span id="char-6" >g</span>
            <span id="char-7" >h</span>
            <span id="char-8" >t</span>
            <span id="char-9" > </span>
            <span id="char-10" >a</span>
            <span id="char-11" >n</span>
            <span id="char-12" >y</span>
            <span id="char-13" > </span>
            <span id="char-14" >w</span>
            <span id="char-15" >o</span>
            <span id="char-16" >r</span>
            <span id="char-17" >d</span>
            <span id="char-18" >s</span>

            {' '} to get started
        </h2>

        <button
            className='cta-button'
            onClick={e => {
              mixpanel.track('Click Try Sample');
              window.open("https://mozilla.github.io/pdf.js/web/viewer.html?file=https://bullets-poc.s3.amazonaws.com/pdf/bitcoin.pdf", "_blank")
            }
            }>Try Sample
        </button>

        {/* <video autoPlay muted loop className="demo-video" >
            <source src={video} type="video/mp4" />
        </video> */}
    </header>
</div>
}

export default Installed;