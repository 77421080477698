import axios from 'axios';
import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
// import loadingIcon from '../../../Assets/loading.svg';
import logo from '../assets/logo.png';
import { Mixpanel } from '../Mixpanel';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // padding: "20px",
    borderWidth: 3,
    borderRadius: '20px',
    borderColor: '#202124',
    borderStyle: 'dashed',
    color: '#202124',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    //   backgroundColor: 'black',
    fontSize: '14px',
    //   height: '200px',
    width:'500px',
    height: '150px',
    // padding: '40px 80px',
    margin: '20px',
    justifyContent: 'center',
    cursor: 'pointer'
};

const activeStyle = {
    borderColor: '#00ae00',
};

const acceptStyle = {
    borderColor: '#00ae00',
};

const rejectStyle = {
    borderColor: 'red',
};

function Upload(props) {
    const [progress, setProgress] = useState('');

    useEffect(() => {
        Mixpanel.track("Visit /upload");
    }, [])

    const onDrop = useCallback((acceptedFiles) => {
        console.log(process.env.REACT_APP_BACKEND_URL)
        axios
            .get(process.env.REACT_APP_BACKEND_URL + '/api/upload')
            .then((res) => {
                const preSignedUrl = res.data;
                console.log(preSignedUrl)
                axios
                    .put(preSignedUrl, acceptedFiles[0], {
                        onUploadProgress: (progressEvent) => {
                            const progressPercentage = Math.round(
                                (progressEvent.loaded * 100) / progressEvent.total
                            );

                            if (progressPercentage === 100) {
                                setProgress('Redirecting...');
                            } else {
                                setProgress(`Uploading ${progressPercentage}%`);
                            }
                        },
                        headers: {
                            Accept: '*/*',
                            'Content-Type': 'application/pdf',
                        },
                    })
                    .then((res) => {
                        console.log(res)
                        const pdfUrl = preSignedUrl.split('?')[0]
                        const pdfViewerUrl = `https://mozilla.github.io/pdf.js/web/viewer.html?file=${pdfUrl}`


                        window.open(pdfViewerUrl, '_self')
                    })
                    .catch((err) => {
                        setProgress('Error');
                    });
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const {
        getRootProps,
        getInputProps,
        acceptedFiles,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({ 
        accept: {'application/pdf': ['.pdf'],},
        maxFiles: 1, 
        onDrop });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    const files = acceptedFiles.map((file) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    return (
        <div className="App">
            <header className="App-header">
                <div style={{ display: 'flex' }}>
                    <img className='logo' src={logo}></img>
                    {/* <div className='header-brand'>Bullets</div> */}
                </div>


                <section
                    className="container clickable"
                // style={{ width: '100%', height: '100%' }}
                >
                    <div {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        {files.length === 1 ? (
                            <h1 className='headline'>{progress && progress}</h1>

                        ) :
                            <div>
                                <h1 className='headline'>Choose PDF File</h1>
                                <div style={{fontSize: '18px'}}>
                                Or drag and drop here

                                </div>

                            </div>
                        }
                    </div>
                </section>

            </header>
        </div>
    );
}

export default Upload;
