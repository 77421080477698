import React, { useEffect, useState } from 'react';
import logo from '../assets/logo.png';
import mixpanel from 'mixpanel-browser';
// import { Mixpanel } from '../Mixpanel';
mixpanel.init(process.env.REACT_APP_MIXPANEL_API_KEY, {
    api_host: process.env.REACT_APP_MIXPANEL_API_HOST,
    ignore_dnt: true,
});
const Landing = () => {

    const [extensionInstallUrl, setExtensionInstallUrl] = useState("https://chrome.google.com/webstore/detail/bulletsai-summarize-in-a/kkhnhlipnekchcngjhgodegacdiebnii")

    useEffect(() => {
        mixpanel.track("Visit /");
        mixpanel.identify()
        mixpanel.people.set()
        // Set Once
        mixpanel.people.set_once({
          utm_source: mixpanel.get_property('utm_source'),
          utm_medium: mixpanel.get_property('utm_medium'),
          utm_campaign: mixpanel.get_property('utm_campaign'),
          utm_term: mixpanel.get_property('utm_term'),
          search_engine: mixpanel.get_property('$search_engine'),
          search_keyword: mixpanel.get_property('mp_keyword'),
            referrer: mixpanel.get_property('$referrer'),
            initial_referrer: mixpanel.get_property('$initial_referrer'),
        });

        // Set UTM Properties
        const utmSource = mixpanel.get_property('utm_source') || 'tooltips_ai_landing_page';
        const utmMedium = mixpanel.get_property('utm_medium') || 'button';
        const utmCampaign = mixpanel.get_property('utm_campaign') || 'tooltips_ai_landing_page';

        // set url query
        const url = new URL(extensionInstallUrl);
        url.searchParams.set('utm_source', utmSource);
        url.searchParams.set('utm_medium', utmMedium);
        url.searchParams.set('utm_campaign', utmCampaign);

        setExtensionInstallUrl(url.href)
    
    }, [])

    var EmbedVideo = function (props) {
        return (
            <div style={{width: "100%", maxWidth: "1000px"}} dangerouslySetInnerHTML={{
                __html: `
                <video
                loop
                muted
                autoplay
                playsinline
                src="${props.src}"
                class="${props.className}"
                />,
            ` }}></div>
        )
    }

    return (
        <div className="App">
            <header className="App-header">
                <div style={{ display: 'flex' }}>
                    <img className='logo' src={logo}></img>
                    {/* <div className='header-brand'>Bullets</div> */}
                </div>

                <h1 className='headline'>Read. Understand. Super Fast.</h1>
                <h2 className='subheadline'>
                Let ChatGPT explain highlighted words <br></br> and answer follow-up questions in 100+ languages
                </h2>
                <button
                    className='cta-button'
                    onClick={e => {
                        // Open the extension in a new tab
                        window.open(extensionInstallUrl, "_blank")
                        // Track the click
                        mixpanel.track("Click Install Extension");
                    }}>Install Extension
                </button>
                <p className="cta-note" style={{marginBottom: "0px"}}>👏 Free—No signup required 👏 </p>
                <p className="cta-note">👏 PDFs supported 👏 </p>
                {/* <p className="cta-note">👏 Supports PDFs & 100+ languages 👏 </p> */}

                <EmbedVideo
                    src="https://audiblogs-conversions.s3.us-west-2.amazonaws.com/images/tooltips-temp/Demo-Multi-Lingual-540p-30fps-cropped.mp4"
                    className="demo-video"
                />

            </header>
        </div>
    )
}

export default Landing;