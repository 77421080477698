// import logo from './logo.svg';
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
// import history from './history';

import Landing from './pages/Landing';
import Installed from './pages/Installed';
import Upload from './pages/Upload';
import Screenshots from './pages/Screenshots';
import HowToUpload from './pages/HowToUpload';
import Privacy from './pages/Privacy';

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route>
        <Route exact path="/" element={<Landing />} />
        <Route exact path="/installed" element={<Installed />} />
        <Route exact path="/upload" element={<Upload />} />
        <Route exact path="/screenshots" element={<Screenshots />} />
        <Route exact path="/how-to-upload" element={<HowToUpload />} />
        <Route exact path="/privacy" element={<Privacy />} />
      </Route>
    </Routes>
  </BrowserRouter>
)

export default App;
