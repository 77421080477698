import React, { useEffect } from 'react';
import logo from '../assets/logo.png';
import { Mixpanel } from '../Mixpanel';
import screenshotOne from '../assets/screenshots/1-words.jpg';
import screenshotTwo from '../assets/screenshots/2-paragraphs.jpg';
import screenshotThree from '../assets/screenshots/3-pdf-url.jpg';
import screenshotFour from '../assets/screenshots/4-upload.jpg';
import screenshotFive from '../assets/screenshots/5-code.jpg';


const Screenshots = () => {

    // Read query params
    const params = new URLSearchParams(window.location.search);
    console.log(params.num)

    // useEffect(() => {
    //     Mixpanel.track("Visit /");
    // }, [])

    return (<div className="App">
        <header className="App-header">
            <div style={{ display: 'flex' }}>
                {/* <img className='logo' src={logo}></img> */}
            </div>
            {/* <h2 className='subheadline'>
            Highlight words or paragraphs anywhere<br />and get simple explanations using AI
            </h2> */}

            {/* <h1 className='headline' style ={{marginBottom: '50px'}}>Explain Words</h1>
            <img className="screenshot" src={screenshotOne}></img> */}

            {/* <h1 className='headline' style ={{marginBottom: '50px'}}>Explain Paragraphs</h1>
            <img className="screenshot" src={screenshotTwo}></img> */}

            {/* <h1 className='headline' style ={{marginBottom: '50px'}}>Supports PDF URLs</h1>
            <img className="screenshot" src={screenshotThree}></img> */}

            {/* <h1 className='headline' style ={{marginBottom: '50px'}}>Supports Local PDF Uploads</h1>
            <img className="screenshot" src={screenshotFour}></img> */}

            <h1 className='headline' style ={{marginBottom: '50px'}}>Explain Code or Foreign Text</h1>
            <img className="screenshot" src={screenshotFive}></img>



        </header>
    </div>)
}

export default Screenshots;